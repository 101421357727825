import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const IntroductionDialog = (props) => {
    const [userName, setUserName] = React.useState('');

    const handleKeyPressedOnText = (e) => {
        if (e.key === 'Enter' && userName !== '') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit(userName);
        }
    };

    return (
        <Dialog open={props.open || false} onClose={() => { }} disableEscapeKeyDown>
            <DialogTitle>Enter your name</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    value={userName}
                    onChange={(event) => setUserName(event.target.value)}
                    onKeyPress={handleKeyPressedOnText}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button type="submit" onClick={handleSubmit} color="primary" disabled={userName === ''}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

export default IntroductionDialog;
