import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@dimoonchepe/videojs-annotation-comments/build/css/annotations.css';
import 'videojs-errors';
import 'videojs-errors/dist/videojs-errors.css';
import 'videojs-contrib-hls.js';
import 'videojs-hlsjs-progress-control';

const useStyles = makeStyles(() => ({
    videoPlayer: {
        width: '100%',
        height: '100%',
    },
}));

// internal component for VideoJS player integration into React lifecycle
const VideoPlayer = React.memo(function VideoPlayer(props) {
    const classes = useStyles();

    const refUpdateCount = React.useRef(0);
    const refPrevUpdateCount = React.useRef(0);
    const refPreviousSrc = React.useRef(null);
    const refVideoNode = React.useRef(null);
    const refPlayer = React.useRef(null);

    const setup = () => {
        refUpdateCount.current = refUpdateCount.current + 1;
    };

    React.useEffect(() => {
        setup();

        return () => {
            if (refPlayer.current) {
                refPlayer.current.dispose();
            }
        };
    }, []);

    React.useEffect(() => {
        if (refPreviousSrc.current !== props.sources) {
            setup();
        }
    }, [props.sources]);

    React.useEffect(() => {
        if (refUpdateCount.current !== refPrevUpdateCount.current) {

            if (refPlayer.current) {
                refPlayer.current.dispose();
            }

            refPlayer.current = videojs(refVideoNode.current, props);
            refPrevUpdateCount.current = refUpdateCount.current;

            if (props.onPlayerUpdated) {
                props.onPlayerUpdated(refPlayer.current);
            }
        }
    });

    return (
        <div key={`${refUpdateCount.current}`} data-vjs-player className={classes.videoPlayer}>
            <video ref={node => refVideoNode.current = node} className={`video-js vjs-big-play-centered ${classes.videoPlayer}`}></video>
        </div>
    );
},

// compare props to make memoization work
(prevProps, nextProps) => {
    return (prevProps.sources[0] === nextProps.sources[0]);
});

export default VideoPlayer;
