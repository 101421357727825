// react
import React from 'react';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';

// material UI
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ChevronLeft, ChevronRight } from '@material-ui/icons';

// local components
import colors from '../assets/colors';
import Menu from './Menu';
import AnnotationsTree from './AnnotationsTree';
import VideoAnnotationEngine from './VideoAnnotationEngine';
import AssetList from './AssetList';
import IntroductionDialog from './IntroductionDialog';
import ConfirmDialog from './ConfirmDialog';
import AnnotationDialog from './AnnotationDialog';
import ShareDialog from './ShareDialog';

import './css/global.css';

// constants
const assetListWidth = 240;
const annotationListWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    assetListDrawer: {
        width: assetListWidth,
        flexShrink: 0,
    },
    annotationListDrawer: {
        width: annotationListWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: assetListWidth,
        backgroundColor: colors.uiBackground,
        color: colors.uiText,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        backgroundColor: colors.uiBackground,
        color: colors.uiText,
    },
    assetListDrawerHeader: {
        justifyContent: 'flex-end',
    },
    annotationListDrawerHeader: {
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -assetListWidth,
        marginRight: -assetListWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    contentShiftLeft: {
        width: `calc(100% - ${assetListWidth}px)`,
        marginLeft: 0,
    },
    contentShiftRight: {
        width: `calc(100% - ${annotationListWidth}px)`,
        marginRight: 0,
    },
    videoWrapper: {
        position: 'relative',
        height: '100vh',
        padding: '65px 0 0 0',
    },
    videoWrapperMobile: {
        position: 'absolute',
        overflow: 'hidden',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: '45px 0 0 0',
    },
    dialogText: {
        color: '#343a40',
    },

    langIcon: {
        width: 22,
        height: 22,
    },
}));

export default function VideoProofTool(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const params = props.match.params;

    // hooks
    const [initialSettings, setInitialSettings] = React.useState(null);
    const [assetUid, setAssetUid] = React.useState(null);
    const [assetURL, setAssetURL] = React.useState(null);
    const [assetFPS, setAssetFPS] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [annotationEngine, setAnnotationEngine] = React.useState(null);

    const [assetListOpen, setAssetListOpen] = React.useState(false);
    const [annotationListOpen, setAnnotationListOpen] = React.useState(false);
    const [forceAnnotationListOpen, setForceAnnotationListOpen] = React.useState(false);

    const [annotationDialogOptions, setAnnotationDialogOptions] = React.useState({});
    const [introductionDialogOpen, setIntroductionDialogOpen] = React.useState(false);
    const [confirmDialogOptions, setConfirmDialogOptions] = React.useState({});
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);

    const [forceReadOnly, setForceReadOnly] = React.useState(false);
    const [blockButtons, setBlockButtons] = React.useState(false);
    const [annotations, setAnnotations] = React.useState(null);
    const [selectionRange, setSelectionRange] = React.useState(null);
    const [selectionShape, setSelectionShape] = React.useState(null);

    // ref hooks to be used inside event handlers
    const refUser = React.useRef(null);
    const refAnnotationEngine = React.useRef(annotationEngine);
    const refAssetUid = React.useRef(assetUid);
    const refAssetURL = React.useRef(assetURL);
    const refAssetFPS = React.useRef(assetFPS);
    const refAnnotations = React.useRef(annotations);
    const refSelectionRange = React.useRef(selectionRange);
    const refSelectionShape = React.useRef(selectionShape);

    const refHubConnection = React.useRef(null);

    // componentDidMount analog
    React.useEffect(() => {
        loadInitialSettings();
        initAnnotationsHub();
    }, []);

    React.useEffect(() => {
        refAnnotationEngine.current = annotationEngine;
    }, [annotationEngine]);

    React.useEffect(() => {
        if (refAssetUid.current) {
            unsubscribeHubFromAsset(refAssetUid.current);
        }

        refAssetUid.current = assetUid;
        if (refAssetUid.current) {
            updateAssetUrl(refAssetUid.current);
            subscribeHubToAsset(refAssetUid.current);
        }
    }, [assetUid]);

    React.useEffect(() => {
        setAssetListOpen(initialSettings && initialSettings.assets && initialSettings.assets.length > 1 && !isMobile);
        if (initialSettings) {
            if (initialSettings.isCommonExternalReview) {
                const searchResult = /(.+) \(by (.+)\)/g.exec(initialSettings.user.displayName);
                if (searchResult && searchResult.length === 3) {
                    loadAnnotations();
                } else {
                    setIntroductionDialogOpen(true);
                }
            }
            document.title = `Project: ${initialSettings.projectName}  | Asset: ${initialSettings.assetName}`;
            refUser.current = initialSettings.user;
        }
    }, [initialSettings]);

    React.useEffect(() => {
        refAssetURL.current = assetURL;
    }, [assetURL]);

    React.useEffect(() => {
        refAssetFPS.current = assetFPS;
    }, [assetFPS]);

    React.useEffect(() => {
        refAnnotations.current = annotations;
        if (!isMobile) {
            setAnnotationListOpen(forceAnnotationListOpen || (annotations !== null && annotations.length > 0));
        }
    }, [annotations]);

    React.useEffect(() => {
        const plugin = refAnnotationEngine.current;
        window.setTimeout(() => {
            if (plugin) plugin.fire('rescaleShapes');
        }, 200);
    }, [annotationListOpen, forceAnnotationListOpen, assetListOpen]);

    React.useEffect(() => {
        refSelectionRange.current = selectionRange;
    }, [selectionRange]);

    React.useEffect(() => {
        refSelectionShape.current = selectionShape;
    }, [selectionShape]);

    // #region -- UI event handlers --

    const handleAssetClick = (assetUid) => {
        if (refAssetUid.current === assetUid) {
            return;
        }
        setAssetUid(assetUid);
        updateAssetUrl(assetUid);
        updateAssetName(assetUid);
        updateFPS(assetUid);
    };

    const handleAnnotationListOpen = () => {
        setAnnotationListOpen(true);
        setForceAnnotationListOpen(true);
    };

    const handleAnnotationListClose = () => {
        setAnnotationListOpen(false);
        setForceAnnotationListOpen(false);
    };

    const handleAddAnnotation = () => {
        const engine = refAnnotationEngine.current;
        if (engine.controls.uiState.adding) {
            engine.fire('cancelAddingAnnotation');
        } else {
            engine.fire('addingAnnotation');
            const annotationCoverDiv = document.querySelector('div.vac-video-cover-canvas');
            annotationCoverDiv.addEventListener('mouseup', handleEndSelectionAnnotationRegion);
            annotationCoverDiv.addEventListener('touchend', handleEndSelectionAnnotationRegion);
        }
    };

    const handleEndSelectionAnnotationRegion = () => {
        if (!refSelectionShape.current) { return; }

        setAnnotationDialogOptions({
            open: true,
            text: '',
            itemType: 'Annotation',
            title: 'Add Annotation',
            onSubmit: handleSubmitNewAnnotation,
            annotationOptions: {
                range: refSelectionRange.current,
                shape: refSelectionShape.current,
            },
        });

        const annotationCoverDiv = document.querySelector('div.vac-video-cover-canvas');
        annotationCoverDiv.removeEventListener('mouseup', handleEndSelectionAnnotationRegion);
        annotationCoverDiv.removeEventListener('touchend', handleEndSelectionAnnotationRegion);
        setSelectionRange(null);
        setSelectionShape(null);
    };

    const handleReplyClicked = (annotationUid) => {
        setAnnotationDialogOptions({
            open: true,
            text: '',
            itemType: 'Reply',
            title: 'Add Reply',
            onSubmit: handleSubmitNewComment,
            annotationOptions: { annotationUid: annotationUid },
        });
    };

    const handleEditAnnotation = (annotationUid) => {
        const annotationToEdit = refAnnotations.current.filter(a => a.commentUid === annotationUid);
        if (annotationToEdit) {
            const a = annotationToEdit[0];
            setAnnotationDialogOptions({
                open: true,
                text: a.body,
                itemType: a.parentCommentUid ? 'Reply' : 'Annotation',
                title: a.parentCommentUid ? 'Edit Reply' : 'Edit Annotation',
                onSubmit: handleSubmitEditAnnotation,
                annotationOptions: {
                    annotationUid: annotationUid,
                    text: a.body,
                    json: a.json,
                },
            });
        }
    };

    const handleSubmitNewAnnotation = (text, annotationOptions) => {
        annotationOptions.commentStr = text || '';
        annotationEngine.fire('cancelAddingAnnotation');
        annotationEngine.fire('newAnnotation', annotationOptions);
        setAnnotationDialogOptions({});
    };

    const handleSubmitNewComment = (text, annotationOptions) => {
        postComment(annotationOptions.annotationUid, text || '');
        setAnnotationDialogOptions({});
    };

    const handleSubmitEditAnnotation = (text, annotationOptions) => {
        editAnnotation(annotationOptions.annotationUid, text || '', annotationOptions.json);
        setAnnotationDialogOptions({});
    };

    const handleAnnotationDialogClose = () => {
        setAnnotationDialogOptions({});
        annotationEngine.fire('cancelAddingAnnotation');
    };

    const handleSubmitUserName = (userName) => {
        saveExternalUserName(userName);
        setIntroductionDialogOpen(false);
    };

    const handleClickOnCommentCard = (annotationUid) => {
        annotationEngine.fire('openAnnotation', { id: annotationUid });
    };

    const handleDeleteAnnotation = (uid) => {
        deleteAnnotation(uid);
    };

    const handleApproveClick = () => {
        setConfirmDialogOptions({
            open: true,
            type: "Approve",
            title: "Approve",
            text: initialSettings.customApproveMessage ? initialSettings.customApproveMessage : "Please confirm you want to approve",
            customAcceptanceRequired: initialSettings.customApproveAcceptanceRequired,
            submitHandler: approve,
            confirmText: "Approve",
            checklist: initialSettings.checklist?.split('||') ?? null,
            onClose: () => { setConfirmDialogOptions({}); },
        });
    };

    const handleRejectClick = () => {
        if (refAnnotations.current && refAnnotations.current.length > 0) {
            setConfirmDialogOptions({
                open: true,
                type: "Reject",
                title: "Request changes",
                text: "Please confirm you want to request changes",
                submitHandler: reject,
                confirmText: "Request changes",
                checklist: initialSettings.checklist?.split('||') ?? null,
                onClose: () => { setConfirmDialogOptions({}); },
            });
        } else {
            setConfirmDialogOptions({
                open: true,
                type: "Reject",
                title: "Annotation is required",
                text: "At least one annotation is required to request changes",
                submitHandler: () => { setConfirmDialogOptions({}); },
                hideCancelButton: true,
                confirmText: 'OK',
                onClose: () => { setConfirmDialogOptions({}); },
            });
        }
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOptions({});
    };

    const handleOpenShareDialog = () => {
        setShareDialogOpen(true);
    };

    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
    };
    // #endregion

    // #region -- Video Player events --

    const onAnnotationEngineReady = (plugin) => {
        if (plugin) {
            setAnnotationEngine(plugin);
        }
        loadAnnotations();
    };

    const onAnnotationAdded = (annotation) => {
        const json = {
            range: annotation.range,
            shape: { a: annotation.shape.a },
        };
        postAnnotation(annotation.id, json, annotation.comments[0].body);
    };

    const onSelectionRangeChanged = (range) => {
        setSelectionRange(range);
    };

    const onSelectionShapeChanged = (shape) => {
        setSelectionShape(shape);
    };
    // #endregion

    // #region -- API calls --

    const loadInitialSettings = () => {
        axios.get(
            '/api/GetVideoProofInitialSettings',
            {
                params:
                {
                    projectUid: params.projectUid,
                    taskUid: params.taskUid,
                    sessionToken: params.sessionToken,
                },
            },
        ).then(response => {
            if (!response.data.error) {
                setBlockButtons(false);
                setInitialSettings(response.data);
                setAssetUid(response.data.assetUid);
                initEscHandler();
            } else {
                throw (response.data.error);
            }
        })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    const saveExternalUserName = (userName) => {
        axios.get(
            '/api/SetExternalUserName',
            {
                params:
                {
                    userName: userName,
                    taskUid: params.taskUid,
                },
            },
        ).then(response => {
            if (response) {
                if (params.sessionToken !== response.data) {
                    params.sessionToken = response.data;
                    const url = `/${params.projectUid}/${params.taskUid}/${params.sessionToken}`;
                    props.history.push(url);
                }
                loadInitialSettings();
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error('deleteComment: failed', error);
            });
    };

    const updateAssetUrl = (assetUid) => {
        axios.get(
            '/api/GetVideoUrl',
            {
                params:
                {
                    assetUid: assetUid,
                },
            },
        ).then(response => {
            if (response.data) {
                updateFPS(assetUid, response.data);
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    const updateFPS = (assetUid, assetUrl) => {
        axios.get(
            '/api/GetVideoFPS',
            {
                params:
                {
                    assetUid: assetUid,
                },
            },
        ).then(response => {
            if (response.data) {
                setAssetFPS(response.data);
                setAssetURL(assetUrl);
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    const updateAssetName = (assetUid) => {
        axios.get(
            '/api/GetVideoName',
            {
                params:
                {
                    assetUid: assetUid,
                },
            },
        ).then(response => {
            if (response.data) {
                document.title = `Project: ${initialSettings.projectName}  | Asset: ${response.data}`;
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    const loadAnnotations = () => {
        if (!refAssetUid.current) {
            return;
        }
        axios.get(
            '/api/GetAnnotations',
            {
                params:
                {
                    projectUid: params.projectUid,
                    assetUid: refAssetUid.current,
                    sessionToken: params.sessionToken,
                },
            },
        ).then(response => {
            if (!response.data.error) {
                setAnnotations(prepareAnnotations(response.data));
            } else {
                throw (response.data.error);
            }
        })
            .catch(error => {
                console.error(error);
                setError(error);
            });
    };

    const postAnnotation = (annotationUid, json, text) => {
        axios.get(
            '/api/PostAnnotation',
            {
                params:
                {
                    assetUid: refAssetUid.current,
                    annotationUid: annotationUid,
                    annotationJson: JSON.stringify(json),
                    annotationText: text,
                    sessionToken: params.sessionToken,
                },
            },
        ).then(response => {
            if (response) {
                updateAnnotationsByHub();
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error('onAnnotationAdded: failed', error);
            });
    };

    const postComment = (annotationUid, text) => {
        axios.get(
            '/api/PostComment',
            {
                params:
                {
                    assetUid: refAssetUid.current,
                    annotationUid: annotationUid,
                    commentText: text,
                    sessionToken: params.sessionToken,
                },
            },
        ).then(() => {
            updateAnnotationsByHub();
        })
            .catch(error => {
                console.error('postComment: failed', error);
            });
    };

    const editAnnotation = (annotationUid, text, json) => {
        axios.get(
            '/api/EditAnnotation',
            {
                params:
                {
                    annotationUid: annotationUid,
                    text: text,
                    json: json,
                },
            },
        ).then(response => {
            if (response.data) {
                updateAnnotationsByHub();
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error('editAnnotation: failed', error);
            });
    };

    const deleteAnnotation = (annotationUid) => {
        axios.get(
            '/api/DeleteAnnotation',
            {
                params:
                {
                    annotationUid: annotationUid,
                },
            },
        ).then(response => {
            if (response) {
                updateAnnotationsByHub();
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error('deleteComment: failed', error);
            });
    };

    const approve = () => {
        setBlockButtons(true);
        axios.post(
            '/api/ApproveTask',
            {
                taskUid: params.taskUid,
                assetUids: initialSettings.assets.map(a => a.assetUid),
                sessionToken: params.sessionToken,
                checklist: params.checklist,
            },
        ).then(response => {
            setConfirmDialogOptions({});
            if (response.data !== '') {
                if (params.taskUid !== response.data) {
                    params.taskUid = response.data;
                    const url = `/${params.projectUid}/${response.data}/${params.sessionToken}`;
                    props.history.push(url);
                }
                if (initialSettings.isCommonExternalReview) {
                    setForceReadOnly(true);
                }
                loadInitialSettings();
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error('approve failed', error);
            });
    };

    const reject = (checklist) => {
        setBlockButtons(true);
        axios.post(
            '/api/RejectTask',
            {
                taskUid: params.taskUid,
                assetUids: initialSettings.assets.map(a => a.assetUid),
                sessionToken: params.sessionToken,
                checklist: checklist,
            },
        ).then(response => {
            setConfirmDialogOptions({});
            if (response.data !== '') {
                if (params.taskUid !== response.data) {
                    params.taskUid = response.data;
                    const url = `/${params.projectUid}/${response.data}/${params.sessionToken}`;
                    props.history.push(url);
                }
                if (initialSettings.isCommonExternalReview) {
                    setForceReadOnly(true);
                }
                loadInitialSettings();
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error('reject failed', error);
            });
    };

    const download = () => {
        window.open('/api/_DownloadVideo?assetUid=' + refAssetUid.current, '_self');
    };

    // #endregion

    // #region -- SignalR --

    const initAnnotationsHub = () => {
        const hubConnection = refHubConnection.current = new signalR.HubConnectionBuilder()
            .withUrl(window.location.origin + '/annotationsHub')
            .withAutomaticReconnect()
            // .configureLogging(signalR.LogLevel.Trace)
            .build();

        hubConnection.serverTimeoutInMilliseconds = 3600000; // 1 hour

        hubConnection.on('UpdateAnnotations', function () {
            loadAnnotations();
        });

        async function start() {
            try {
                await hubConnection.start();
                console.log('SignalR Connected.');
            } catch (err) {
                console.log(err);
                setTimeout(start, 5000);
            }
        }

        hubConnection.onclose(start);

        // Start the connection.
        start();
    };

    const subscribeHubToAsset = (assetUid) => {
        const hubConnection = refHubConnection.current;
        if (hubConnection.state === signalR.HubConnectionState.Connected) {
            hubConnection.invoke('SubscribeToAsset', assetUid)
                .catch((err) => {
                    return console.error(err.toString());
                });
        }
    };

    const unsubscribeHubFromAsset = (assetUid) => {
        const hubConnection = refHubConnection.current;
        if (hubConnection.state === signalR.HubConnectionState.Connected) {
            hubConnection.invoke('UnsubscribeFromAsset', assetUid)
                .catch((err) => {
                    return console.error(err.toString());
                });
        }
    };

    const updateAnnotationsByHub = () => {
        loadAnnotations();
        if (refHubConnection.current && refHubConnection.current.state === signalR.HubConnectionState.Connected) {
            refHubConnection.current.invoke('PostAnnotation', refAssetUid.current)
                .catch((err) => {
                    return console.error(err.toString());
                });
        }
    };

    // #endregion

    // #region -- Helpers --

    const initEscHandler = () => {
        document.onkeydown = function (evt) {
            evt = evt || window.event;
            let isEscape = false;
            if ('key' in evt) {
                isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
            } else {
                isEscape = (evt.keyCode === 27);
            }
            if (isEscape) {
                const engine = refAnnotationEngine.current;
                if (engine && engine.controls.uiState.adding) {
                    setAnnotationDialogOptions({});
                    engine.fire('cancelAddingAnnotation');
                }
            }
        };
    };

    const prepareAnnotations = (annotations) => {
        const user = refUser.current;
        if (!user) { return null; }

        const preparedAnnotations = annotations.map(annotation => {
            const isUserOwner =
                !introductionDialogOpen &&
                annotation.userUid === user.userUid &&
                annotation.userName === user.displayName;

            return {
                ...annotation,
                isUserOwner: isUserOwner,
            };
        });
        return preparedAnnotations;
    };

    // #endregion

    //  #region -- Render --

    const user = initialSettings && {
        id: initialSettings.user.userUid,
        name: initialSettings.user.displayName,
        email: initialSettings.user.email,
        language: initialSettings.user.preferredLanguage,
    };

    if (error !== null && error.code !== 'ECONNABORTED') {
        props.history.push('/wronglink/');
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Menu
                onOpenAssetList={() => setAssetListOpen(true)}
                onAnnotationTreeOpen={handleAnnotationListOpen}
                onAddAnnotation={handleAddAnnotation}
                onApproveClick={handleApproveClick}
                onRejectClick={handleRejectClick}
                onShareClick={handleOpenShareDialog}
                assetListWidth={assetListWidth}
                annotationListWidth={annotationListWidth}
                assetListOpen={assetListOpen}
                annotationListOpen={annotationListOpen}
                annotationDisabled={!annotationEngine}
                approvalAllowed={initialSettings && annotations !== null && !initialSettings.isQuickView && !initialSettings.isReadOnly && !forceReadOnly}
                approvalDisabled={blockButtons}
                isMobile={isMobile}
                sharingEnabled={initialSettings && !initialSettings.isExternalReview}
                downloadEnabled={initialSettings && initialSettings.isDownloadEnabled}
                onDownloadClick={download}
            />
            <Drawer id="assetList"
                className={classes.assetListDrawer}
                variant="persistent"
                anchor="left"
                open={assetListOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={`${classes.drawerHeader} ${classes.assetListDrawerHeader}`}>
                    <IconButton color="inherit" onClick={() => setAssetListOpen(false)}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Divider />
                <AssetList
                    assets={initialSettings ? initialSettings.assets : []}
                    onAssetClick={handleAssetClick}
                />
            </Drawer>

            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: assetListOpen || annotationListOpen,
                    [classes.contentShiftLeft]: assetListOpen,
                    [classes.contentShiftRight]: annotationListOpen,
                })}
            >
                <div className={[classes.drawerHeader, classes.assetListDrawerHeader]} />
                <Container maxWidth={false} className={isMobile ? classes.videoWrapperMobile : classes.videoWrapper}>
                    {initialSettings && assetURL
                        ? (
                            <VideoAnnotationEngine className={classes.videoPlayer}
                                src={assetURL}
                                fps={assetFPS}
                                user={user}
                                isMobile={isMobile}
                                controls={true}
                                annotations={annotations}
                                onAnnotationEngineReady={onAnnotationEngineReady}
                                onAnnotationAdded={onAnnotationAdded}
                                onSelectionRangeChanged={onSelectionRangeChanged}
                                onSelectionShapeChanged={onSelectionShapeChanged}
                            />
                        )
                        : (<div />)}
                </Container>

            </main>

            <Drawer id="annotationsList"
                className={classes.annotationListDrawer}
                variant="persistent"
                anchor="right"
                open={annotationListOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={`${classes.drawerHeader} ${classes.annotationListDrawerHeader}`}>
                    <IconButton color="inherit" onClick={handleAnnotationListClose}>
                        {theme.direction === 'ltr' ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </div>
                <Divider />
                <AnnotationsTree
                    annotations={annotations}
                    handleReply={handleReplyClicked}
                    handleClickOnCard={handleClickOnCommentCard}
                    handleEditAnnotation={handleEditAnnotation}
                    handleDeleteAnnotation={handleDeleteAnnotation}
                />
            </Drawer>

            <IntroductionDialog
                open={introductionDialogOpen}
                onSubmit={handleSubmitUserName}
            />

            <ConfirmDialog
                onClose={handleConfirmDialogClose}
                {...confirmDialogOptions}
            />

            <AnnotationDialog
                onClose={handleAnnotationDialogClose}
                {...annotationDialogOptions}
            />

            <ShareDialog
                open={shareDialogOpen}
                userLanguage={user && user.language}
                onClose={handleShareDialogClose}
                projectUid={params.projectUid}
                assetUids={initialSettings && initialSettings.assets.map(a => a.assetUid)}
                sessionToken={params.sessionToken}
            />

        </div>
    );

    // #endregion
}
