import React from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../assets/colors';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { VideoLibrary, Chat, ThumbUp, ThumbDown, Create, Share, GetApp } from '@material-ui/icons';

const Menu = React.memo(function Menu(props) {
    const useStyles = makeStyles((theme) => ({
        toolBarMobile: {
            paddingRight: 4,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        menuButtonMobile: {
            marginRight: 0,
            minWidth: 30,

        },
        buttonIcon: {
            marginLeft: -4,
            marginRight: 8,
            fontSize: 20,
        },
        buttonIconMobile: {
            fontSize: 20,
        },
        logo: {
            height: 32,
            marginRight: 24,
            marginLeft: -10,
        },
        appBar: ({
            backgroundColor: colors.uiBackground,
            marginLeft: 0,
            marginRight: 0,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
        appBarShift: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        appBarShiftLeft: {
            width: `calc(100% - ${props.assetListWidth}px)`,
            marginLeft: props.assetListWidth,
        },
        appBarShiftRight: {
            width: `calc(100% - ${props.annotationListWidth}px)`,
            marginRight: props.annotationListWidth,
        },
        appBarShiftBoth: {
            width: `calc(100% - ${props.assetListWidth + props.annotationListWidth}px)`,
            marginLeft: props.assetListWidth,
            marginRight: props.annotationListWidth,
        },
        reviewButtonContainer: {
            minWidth: 120,
        },
        reviewButton: {
            margin: theme.spacing(1),
            color: 'white',
        },
        reviewButtonMobile: {
            margin: theme.spacing(1) / 2,
            color: 'white',
            minWidth: 0,
        },
        approveButton: {
            backgroundColor: colors.approveButton,
        },
        rejectButton: {
            backgroundColor: colors.rejectButton,
        },
        grow: {
            flexGrow: 1,
        },
        hide: {
            display: 'none',
        },
    }));
    const classes = useStyles();

    const respClasses = {
        menuButton: props.isMobile ? classes.menuButtonMobile : classes.menuButton,
        reviewButton: props.isMobile ? classes.reviewButtonMobile : classes.reviewButton,
        buttonIcon: props.isMobile ? classes.buttonIconMobile : classes.buttonIcon,
        toolBar: props.isMobile ? classes.toolBarMobile : '',
    };

    return (
        <AppBar position="fixed" className={clsx(classes.appBar, {
            [classes.appBarShift]: props.assetListOpen || props.annotationListOpen,
            [classes.appBarShiftLeft]: props.assetListOpen,
            [classes.appBarShiftRight]: props.annotationListOpen,
            [classes.appBarShiftBoth]: props.assetListOpen && props.annotationListOpen,
        })}>
            <Toolbar className={respClasses.toolBar}>
                <Hidden xsDown>
                    <img src="/img/appstudio-logo.png" className={classes.logo} alt="Approval Studio Video Proof Tool" />
                </Hidden>
                <IconButton color="inherit" aria-label="open assets list" onClick={props.onOpenAssetList} edge="start" className={clsx(respClasses.menuButton, props.assetListOpen && classes.hide)}>
                    <VideoLibrary />
                </IconButton>
                <IconButton color="inherit" title="Add annotation" aria-label="annotate" disabled={props.annotationDisabled} onClick={props.onAddAnnotation} edge="start" className={respClasses.menuButton}>
                    <Create />
                </IconButton>
                <div className={classes.grow} />
                {props.approvalAllowed
                    ? (
                        <div className={classes.reviewButtonContainer}>
                            <Button variant="contained" disabled={props.approvalDisabled} onClick={props.onApproveClick} className={clsx(respClasses.reviewButton, classes.approveButton)}>
                                <ThumbUp className={respClasses.buttonIcon} />
                                {!props.isMobile ? 'Approve' : null}
                            </Button>
                            <Button variant="contained" disabled={props.approvalDisabled} onClick={props.onRejectClick} className={clsx(respClasses.reviewButton, classes.rejectButton)}>
                                <ThumbDown className={respClasses.buttonIcon} />
                                {!props.isMobile ? 'Request Changes' : null}
                            </Button>
                        </div>
                    )
                    : null
                }
                <div className={classes.grow} />
                {props.downloadEnabled && !props.isMobile
                    ? (
                        <IconButton color="inherit" aria-label="download" onClick={props.onDownloadClick} edge="end" className={respClasses.menuButton} title="Download Video">
                            <GetApp />
                        </IconButton>
                    )
                    : null}
                {props.sharingEnabled
                    ? (
                        <IconButton color="inherit" aria-label="share" onClick={props.onShareClick} edge="end" className={respClasses.menuButton} title="Create external review">
                            <Share />
                        </IconButton>
                    )
                    : null
                }
                <IconButton color="inherit" aria-label="open annotations list" onClick={props.onAnnotationTreeOpen} edge="end" className={clsx(respClasses.menuButton, props.annotationListOpen && classes.hide)}>
                    <Chat />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
});

export default Menu;
