const colors = {
    uiBackground: '#343a40',
    uiText: '#ffffff',
    annotationTimestamp: '#73859f',
    assetListText: '#cccccc',
    selectedAssetBackground: '#cccccc44',
    approveButton: '#28a745',
    rejectButton: '#dc3545',
};

export default colors;
