import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import colors from '../assets/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 240,
        padding: 20,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: colors.uiText,
        backgroundColor: colors.uiBackground,
    },
}));

const AnnotationsTree = React.memo(function AnnotationsTree(props) {
    const classes = useStyles();

    const formatTime = (timeInSeconds) => {
        let hours = Math.floor(timeInSeconds / 3600);
        let minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
        let seconds = timeInSeconds - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = '0' + hours; }
        if (minutes < 10) { minutes = '0' + minutes; }
        if (seconds < 10) { seconds = '0' + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    };

    const formatRange = (range) => {
        if (range.start === range.end) {
            return `${formatTime(range.start)}`;
        } else {
            return `${formatTime(range.start)} - ${formatTime(range.end)}`;
        }
    };

    const renderTree = (parentUid, level, rootAnnotationUid) => {
        try {
            const annotationItems = [];
            const rootAnnotations = props.annotations ? props.annotations.filter(a => a.parentCommentUid === parentUid) : [];
            rootAnnotations.forEach(annotation => {
                const rootUid = annotation.parentCommentUid ? rootAnnotationUid : annotation.commentUid;
                const json = annotation.json ? JSON.parse(annotation.json) : null;

                const canBeDeleted = props.annotations.filter(a => a.parentCommentUid === annotation.commentUid).length === 0;
                const item = (
                    <AnnotationItem
                        key={annotation.commentUid}
                        nodeId={annotation.commentUid}
                        userName={annotation.userName}
                        message={annotation.body}
                        range={annotation.parentCommentUid ? null : formatRange(json.range)}
                        date={annotation.createdDateTime}
                        level={level}
                        rootAnnotationUid={rootUid}
                        isEditable={annotation.isUserOwner}
                        isDeletable={annotation.isUserOwner && canBeDeleted}
                        handleReply={() => props.handleReply(annotation.commentUid)}
                        handleClickOnCard={props.handleClickOnCard}
                        handleEdit={props.handleEditAnnotation}
                        handleDelete={props.handleDeleteAnnotation}
                    >
                        {renderTree(annotation.commentUid, level + 1, rootUid)}

                    </AnnotationItem>
                );
                annotationItems.push(item);
                return item;
            });

            return annotationItems;
        } catch (error) {
            console.error(error);
            return (<div />);
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                {renderTree(null, 0)}
            </Grid>
        </div>
    );
},
// compare props to make memoization work
(prevProps, nextProps) => {
    return (prevProps.annotations === nextProps.annotations);
},
);

const useAnnotationItemStyles = makeStyles(() => ({
    cardRoot: {
        minWidth: 170,
    },
    cardWrapper: {
        marginTop: 5,
    },
    cardContent: {
        padding: 10,
    },
    timestamp: {
        fontSize: 10,
        color: colors.annotationTimestamp,
    },
    userName: {
        fontSize: 12,
        paddingTop: 5,
    },
    grow: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    menuButton: {
        '&:focus': {
            outline: 'none',
        },
    },
}));

function AnnotationItem(props) {
    const classes = useAnnotationItemStyles();

    const { userName, message, date, level } = props;
    const shift = (level > 0 && level <= 4) ? 10 : 0;
    const panelWidth = 205;
    const width = panelWidth - (10 * Math.min(level, 4));
    const [anchorEl, setAnchorEl] = React.useState(null);

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            closeMenu();
        }
    };

    const menuButton = props.isEditable || props.isDeletable
        ? (
            <IconButton color="inherit" aria-label="comment actions" className={classes.menuButton} onClick={(e) => { setAnchorEl(e.currentTarget); }}>
                <MoreVert />
            </IconButton>
        )
        : null;

    const editMenuItem = props.isEditable
        ? (
            <MenuItem onClick={() => { closeMenu(); props.handleEdit(props.nodeId); }}>Edit</MenuItem>
        )
        : null;

    const deleteMenuItem = props.isDeletable
        ? (
            <MenuItem onClick={() => { closeMenu(); props.handleDelete(props.nodeId); }}>Delete</MenuItem>
        )
        : null;

    return (
        <div className={classes.cardWrapper} style={{ marginLeft: shift }}>
            <Card style={{ minWidth: width }} onClick={() => props.handleClickOnCard(props.rootAnnotationUid)}>
                <CardContent className={classes.cardContent}>
                    <Typography align="right" className={classes.timestamp}>
                        {date}
                    </Typography>
                    <Typography variant="body2" className={classes.userName}>
                        {userName}:
                    </Typography>
                    <Typography className={classes.message} >
                        {message}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" color="secondary" onClick={props.handleReply}>Reply</Button>
                    <div className={classes.grow} />
                    {menuButton}
                    <Popover
                        id={anchorEl !== null ? 'comment-menu' : undefined}
                        open={anchorEl !== null}
                        anchorEl={anchorEl}
                        onClose={closeMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Paper>
                            <MenuList autoFocusItem={anchorEl !== null} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {editMenuItem}
                                {deleteMenuItem}
                            </MenuList>
                        </Paper>
                    </Popover>
                </CardActions>
            </Card>
            {props.children}
        </div>
    );
}

export default AnnotationsTree;
