import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const ConfirmDialog = (props) => {
    const [approveConfirmed, setApproveConfirmed] = React.useState(false);
    const [blockSubmit, setBlockSubmit] = React.useState(false);

    React.useEffect(() => { 
        if (props.open) {
            if (props.checklist && props.type==="Approve") {
                setBlockSubmit(true);
            } else {
                setBlockSubmit(false);
            }
        }
    }, [props.open]);

    const handleCheck = (event) => {
        if (props.customAcceptanceRequired) {
            setApproveConfirmed(event.target.checked);
        }
    };

    const handleSubmit = () => {
        setBlockSubmit(true);
        props.submitHandler(checkedChecklist);
    };

    const [checkedChecklist, setCheckedChecklist] = React.useState("");

    const handlerCheckedChecklist = (value) => {
        setCheckedChecklist(value);
    };

    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);

        if (props.type === "Approve") {
            if ((newChecked.length ) >= props.checklist.length) {
                setBlockSubmit(false);
            } else {
                setBlockSubmit(true);
            }
        }

        handlerCheckedChecklist(newChecked.toString());
    };

    return (
        <Dialog open={!!props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent id="confirm-modal">
                {props.customAcceptanceRequired ? (
                    <FormControlLabel
                        control={<Checkbox checked={approveConfirmed} onChange={handleCheck} />}
                        label={props.text}
                    />
                ) : (
                    <Typography>
                        {props.text}
                    </Typography>
                )}
                {props.checklist ? (
                    <List >
                        {props.checklist.map((value) => {
                            const labelId = `checkbox-list-label-${value}`;

                            return (
                                <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />

                                    <ListItemText id={labelId} primary={`${value}`} />
                                </ListItem>
                            );
                        })}
                    </List>
                ) : (<></>)         
                }
            </DialogContent>
            <DialogActions>
                {!props.open || props.hideCancelButton  ? (
                    <div />
                ): (
                    <Button onClick = {props.onClose} color="secondary">
                        Cancel
                    </Button>
                )}
                <Button type="submit" disabled={blockSubmit || (props.customAcceptanceRequired && !approveConfirmed)} onClick={handleSubmit} color="primary">
                    {props.confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
