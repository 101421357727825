import React from 'react';
import 'date-fns';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as FlagEnglish } from '../assets/flags/flag_en.svg';
import { ReactComponent as FlagGerman } from '../assets/flags/flag_de.svg';
import { ReactComponent as FlagSpanish } from '../assets/flags/flag_es.svg';
import { ReactComponent as FlagFrench } from '../assets/flags/flag_fr.svg';
import { ReactComponent as FlagHebrew } from '../assets/flags/flag_he.svg';
import { ReactComponent as FlagPolish } from '../assets/flags/flag_pl.svg';
import { ReactComponent as FlagUkrainian } from '../assets/flags/flag_ua.svg';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddLinkIcon from '@material-ui/icons/Link';
import SendIcon from '@material-ui/icons/Send';
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    dialogSection: {
        marginBottom: theme.spacing(3),
    },
    email: {
        marginBottom: theme.spacing(2),
    },
    langButton: {
        cursor: "pointer",
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    langIcon: {
        width: 22,
        height: 22,
    },
    languageMenuItemLabel: {
        paddingLeft: theme.spacing(2),
    },
    advancedSection: {
        marginTop: theme.spacing(3),
    },
    checkboxLabel: {
        marginBottom: 0,
    },
    datePicker: {
        width: "100%",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ShareDialog = React.memo(function ShareDialog(props) {
    const classes = useStyles();

    const languages = [
        { code: 'en', label: 'English', image: (<FlagEnglish className={classes.langIcon} />) },
        { code: 'de', label: 'Deutch', image: (<FlagGerman className={classes.langIcon} />) },
        { code: 'es', label: 'Español', image: (<FlagSpanish className={classes.langIcon} />) },
        { code: 'fr', label: 'Français', image: (<FlagFrench className={classes.langIcon} />) },
        { code: 'he', label: 'עברי', image: (<FlagHebrew className={classes.langIcon} />) },
        { code: 'pl', label: 'Polski', image: (<FlagPolish className={classes.langIcon} />) },
        { code: 'ua', label: 'Ukrainian', image: (<FlagUkrainian className={classes.langIcon} />) },
    ];

    const [emailText, setEmailText] = React.useState("");
    const [emailValid, setEmailValid] = React.useState(false);
    const [language, setLanguage] = React.useState(null);
    const [languageChooser, setLanguageChooser] = React.useState(null);

    const [readOnly, setReadOnly] = React.useState(false);
    const [allowDownload, setAllowDownload] = React.useState(true);
    const [advancedExpanded, setAdvancedExpanded] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [subjectText, setSubjectText] = React.useState("");
    const [passwordText, setPasswordText] = React.useState("");
    const [commentText, setCommentText] = React.useState("");
    const [shareUrl, setShareUrl] = React.useState("");
    const [alertOptions, setAlertOptions] = React.useState({});
    const [blockSend, setBlockSend] = React.useState(false);

    const shareUrlRef = React.createRef();

    React.useEffect(() => {
        if (!props.userLanguage) {
            setLanguage(languages[0]);
            return;
        }

        const language = languages.filter(ln => ln.code === props.userLanguage);
        if (language.length) {
            setLanguage(language[0]);
        } else {
            setLanguage(languages[0]);
        }
    }, []);

    React.useEffect(() => {
        if (props.open) {
            setBlockSend(false);
        }
    }, [props.open]);

    React.useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i;
        const isValid = emailRegex.test(emailText);
        setEmailValid(isValid);
    }, [emailText]);

    const handleEmailChange = (event) => {
        setEmailText(event.target.value);
    };

    const formatDate = (date) => {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const handleSendReview = () => {
        setBlockSend(true);
        props.onClose();
        axios.post("/api/CreateExternalReview", {
            projectUid: props.projectUid,
            email: emailText,
            readOnly: readOnly,
            allowDownload: allowDownload,
            dueDateString: selectedDate && !isNaN(selectedDate.getDate()) ? formatDate(selectedDate) : null,
            subject: subjectText,
            password: passwordText,
            comment: commentText,
            locale: language.code,
            assetUids: props.assetUids,
            sessionToken: props.sessionToken,
        }).then(response => {
            if (response.data === "") {
                throw (response);
            }
        })
            .catch(error => {
                console.error("approve failed", error);
            });
    };

    const handleClickLanguageChooser = (event) => {
        setLanguageChooser(event.currentTarget);
    };

    const chooseLanguage = (lang) => {
        setLanguage(lang);
        setLanguageChooser(null);
    };

    const handleChangeDueDate = (date) => {
        setSelectedDate(date);
    };

    const fillShareUrl = () => {
        axios.post("/api/GetShareUrl", {
            projectUid: props.projectUid,
            assetUids: props.assetUids,
            sessionToken: props.sessionToken,
        }).then(response => {
            if (response.data !== "") {
                setShareUrl(response.data);
            } else {
                throw (response);
            }
        })
            .catch(error => {
                console.error("GetShareUrl failed", error);
            });
    };

    const handleClickCopyUrl = () => {
        shareUrlRef.current.querySelector("input").select();
        document.execCommand("copy");

        if (window.getSelection) {
            window.getSelection().removeAllRanges();
        } else if (document.selection) {
            document.selection.empty();
        }
        setAlertOptions({ open: true, message: "Link is copied to the clipboard" });
    };

    const languageMenu = (
        <Menu
            id="simple-menu"
            anchorEl={languageChooser}
            keepMounted
            open={Boolean(languageChooser)}
            onClose={() => setLanguageChooser(null)}
        >
            {languages.map(ln => {
                return (
                    <MenuItem key={ln.code} onClick={() => { chooseLanguage(ln); }}>
                        {ln.image} <Typography className={classes.languageMenuItemLabel}>{ln.label}</Typography></MenuItem>
                );
            })}
        </Menu>
    );

    const advancedSection = (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={allowDownload}
                                color="primary"
                                onChange={(event) => setAllowDownload(event.target.checked)} />
                        }
                        label="Allow Download"
                        className={classes.checkboxLabel}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={readOnly}
                                color="primary"
                                onChange={(event) => setReadOnly(event.target.checked)} />
                        }
                        label="Read Only"
                        className={classes.checkboxLabel}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={classes.datePicker}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            label="Due Date (optional)"
                            value={selectedDate}
                            autoOk={true}
                            onChange={date => handleChangeDueDate(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Subject (optional)"
                        fullWidth
                        value={subjectText}
                        onChange={(event) => setSubjectText(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Password (optional)"
                        type='password'
                        autoComplete='off'
                        fullWidth
                        value={passwordText}
                        onChange={(event) => setPasswordText(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Comment (optional)"
                        fullWidth
                        multiline
                        rows={4}
                        value={commentText}
                        onChange={(event) => setCommentText(event.target.value)}
                    />
                </Grid>
            </Grid>
        </form>
    );

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth="xs" aria-labelledby="form-dialog-title">
            <DialogTitle id="share-dialog-title">Create external review</DialogTitle>
            <DialogContent>
                <Card className={classes.dialogSection}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                            By Email
                        </Typography>

                        <FormControl fullWidth className={classes.email}>
                            <TextField
                                autoFocus
                                label="Email"
                                value={emailText}
                                onChange={handleEmailChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.langButton} onClick={handleClickLanguageChooser} title="Choose Language">
                                            {language ? language.image : null}
                                        </InputAdornment>
                                    ),
                                }}
                                error={(emailText && !emailValid) ? true : false}
                                helperText={(emailText && !emailValid) ? "Incorrect Email" : ""}
                            />
                        </FormControl>
                        {languageMenu}

                        <Collapse in={advancedExpanded} timeout="auto" unmountOnExit>
                            {advancedSection}
                        </Collapse>

                        <CardActions>
                            <Button
                                color="secondary"
                                startIcon={advancedExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                onClick={() => setAdvancedExpanded(!advancedExpanded)}>
                                {advancedExpanded ? "Hide Advanced" : "Advanced"}
                            </Button>
                            <div className={classes.grow} />
                            <Button className={classes.sendButton} color="primary" endIcon={<SendIcon />} disabled={blockSend || !emailValid} onClick={handleSendReview}>Send</Button>
                        </CardActions>
                    </CardContent>
                </Card>

                <Card >
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                            By Link
                        </Typography>

                        <FormControl fullWidth>
                            <div className={classes.flexRow}>
                                <TextField
                                    className={classes.grow}
                                    label={shareUrl ? 'Short Link' : 'Generate a short link'}
                                    ref={shareUrlRef}
                                    readOnly
                                    value={shareUrl}
                                />
                                {
                                    shareUrl ? (
                                        <IconButton id="btn-copy-share-link" onClick={handleClickCopyUrl} title="Copy short link">
                                            <FileCopyIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton id="btn-generate-share-link" onClick={fillShareUrl} title="Create short link">
                                            <AddLinkIcon />
                                        </IconButton>
                                    )
                                }
                            </div>
                        </FormControl>
                    </CardContent>
                </Card>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={alertOptions.open}
                    autoHideDuration={6000}
                    onClose={() => setAlertOptions({})}
                >
                    <Alert severity="success" onClose={() => setAlertOptions({})}>
                        {alertOptions.message}
                    </Alert>
                </Snackbar>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

},

// compare props to make memoization work
(prevProps, nextProps) => {
    return (prevProps.open === nextProps.open);
});

export default ShareDialog;
