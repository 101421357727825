import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import VideoProofTool from './components/VideoProofTool';
import { WrongLink } from './components/WrongLink';
import 'fontsource-roboto';


export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Switch>
                <Route path='/:projectUid/:taskUid/:sessionToken' component={VideoProofTool} />
                <Route path='/:projectUid/:taskUid/' component={VideoProofTool} />
                <Route path='/wronglink/' component={WrongLink} />
                <Route path='/' component={WrongLink} />
            </Switch>
        );
    }
}