import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const AnnotationDialog = (props) => {
    const [commentText, setCommentText] = React.useState('');

    React.useEffect(() => {
        setCommentText(props.text || '');
    }, [props.text]);

    const handleKeyPressedOnText = (e) => {
        if (e.key === 'Enter' && commentText !== '') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit(commentText, props.annotationOptions);
        }
    };

    const handleClose = () => {
        setCommentText('');
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <Dialog open={!!props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={`${props.itemType || ''} text`}
                    type="text"
                    autoComplete="off"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    onKeyPress={handleKeyPressedOnText}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button type="submit" onClick={handleSubmit} color="primary" disabled={commentText === ''}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AnnotationDialog;
