import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import MovieIcon from '@material-ui/icons/Movie';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import colors from '../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        // paddingTop: 10
    },
}));

const AssetList = React.memo(function AssetList(props) {
    const classes = useStyles();
    const grouppedAssets = {};
    props.assets.forEach(asset => {
        if (!grouppedAssets[asset.name]) { grouppedAssets[asset.name] = []; }
        grouppedAssets[asset.name].push({ version: asset.cycleNum, assetUid: asset.assetUid, info: asset.assetInfo });
    });

    const useTreeItemStyles = makeStyles((theme) => ({
        root: {
            color: colors.assetListText,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, ${colors.selectedAssetBackground})`,
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        content: {
            color: colors.assetListText,
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightRegular,
            '$expanded > &': {
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
        group: {
            marginLeft: 0,
            '& $content': {
                paddingLeft: theme.spacing(2),
            },
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
        },
    }));

    const AssetItem = (props) => {
        const classes = useTreeItemStyles();
        const { labelText, nodeId, ...other } = props;

        const onAssetClick = (nodeId) => {
            if (grouppedAssets[nodeId]) {
                const asset = grouppedAssets[nodeId][0];
                selectAsset(asset.assetUid);
            }
        };

        return (
            <TreeItem
                nodeId={nodeId}
                key={nodeId}
                onClick={() => onAssetClick(nodeId)}
                label={
                    <div className={classes.labelRoot}>
                        <VideoLibraryIcon color="inherit" className={classes.labelIcon} />
                        <Typography variant="body2" className={classes.labelText}>
                            {labelText}
                        </Typography>

                    </div>
                }
                classes={{
                    root: classes.root,
                    content: classes.content,
                    expanded: classes.expanded,
                    selected: classes.selected,
                    group: classes.group,
                    label: classes.label,
                }}
                {...other}
            />
        );
    };

    const AssetVersionItem = (props) => {
        const classes = useTreeItemStyles();
        const { labelText, nodeId, handleClick, ...other } = props;

        return (
            <TreeItem
                key={nodeId}
                nodeId={nodeId}
                label={
                    <div className={classes.labelRoot}>
                        <MovieIcon color="inherit" className={classes.labelIcon} />
                        <Typography variant="body2" className={classes.labelText}>
                            {labelText}
                        </Typography>

                    </div>
                }
                onClick={() => handleClick(nodeId)}
                classes={{
                    root: classes.root,
                    content: classes.content,
                    expanded: classes.expanded,
                    selected: classes.selected,
                    group: classes.group,
                    label: classes.label,
                }}
                {...other}
            />
        );
    };

    const selectAsset = (assetUid) => {
        props.onAssetClick(assetUid);
    };

    const renderTree = () => {
        return Object.keys(grouppedAssets).map(name => {
            return (
                <AssetItem key={name} nodeId={name} labelText={name} >
                    {grouppedAssets[name].map(asset =>
                        <AssetVersionItem
                            key={asset.assetUid}
                            nodeId={asset.assetUid}
                            labelText={`Version ${asset.version}`}
                            handleClick={() => selectAsset(asset.assetUid)} />,
                    )}
                </AssetItem>
            );
        });
    };

    return (<TreeView
        className={classes.root}
        defaultExpanded={['3']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
    >
        {renderTree()}
    </TreeView>);
},
// compare props to make memoization work
(prevProps, nextProps) => {
    return (prevProps.assets === nextProps.assets);
},
);

export default AssetList;
