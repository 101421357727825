import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import videojs from 'video.js';
import '../assets/css/player-icons.css';
import '@dimoonchepe/videojs-annotation-comments/build/css/annotations.css';
import VideoPlayer from './VideoPlayer';
import AnnotationComments from '@dimoonchepe/videojs-annotation-comments';

const useStyles = makeStyles(() => ({
    videoPlayer: {
        width: '100%',
        height: '100%',
    },
}));

videojs.registerPlugin('annotationComments', AnnotationComments(videojs));

const VideoAnnotationEngine = React.memo(function VideoAnnotationEngine(props) {
    const classes = useStyles();

    const refAnnotationEngine = React.useRef();
    const refAnnotations = React.useRef([]);
    const [player, setPlayer] = React.useState(null);
    const refPlayer = React.useRef(player);
    const refLastTime = React.useRef(0);

    React.useEffect(() => {
        if (!player) {
            return;
        }
        if (!refPlayer.current || refPlayer.current.id() !== player.id()) {
            setControlsVisibility(player, props.hideControls);
            initAnnotationEngine(player);
            refPlayer.current = player;
        }
    }, [player]);

    React.useEffect(() => {
        if (refPlayer.current) {
            setControlsVisibility(refPlayer.current, props.hideControls);
        }
    }, [props.hideControls]);

    const onPlayerUpdated = (player) => {
        setPlayer(player);
    };

    // this is called every time annotations list is updated by parent
    React.useEffect(() => {
        if (!refAnnotationEngine.current || !refAnnotationEngine.current.annotationState) {
            return;
        }
        refAnnotations.current = props.annotations;
        updateAnnotations();
        refPlayer.current.hasStarted(true);
        handleTimeChanged(refPlayer.current.currentTime());
    }, [props.annotations]);

    const initAnnotationEngine = (player) => {
        const plugin = player.annotationComments({
            annotationsObjects: [],
            bindArrowKeys: true,
            showControls: false,
            showCommentList: false,
            showFullScreen: true,
            showMarkerShapeAndTooltips: true,
            internalCommenting: true,
            startInAnnotationMode: true,
        });
        plugin.onReady(() => {
            initAnnotationEngineEvents(plugin);
            initFrameSeeker();
            refAnnotationEngine.current = plugin;
            if (props.onAnnotationEngineReady) {
                props.onAnnotationEngineReady(plugin);
            }
        });
        player.on('timeupdate', () => { handleTimeChanged(player.currentTime()); });
    };

    const initFrameSeeker = () => {
        const VjsButton = videojs.getComponent('Button');
        const step = 1 / props.fps;

        const forwardButtonClass = videojs.extend(VjsButton, {
            constructor: function () {
                VjsButton.call(this, player);
            },

            handleClick: function () {
                player.currentTime(player.currentTime() + step);
            },
        });

        const backwardButtonClass = videojs.extend(VjsButton, {
            constructor: function () {
                VjsButton.call(this, player);
            },

            handleClick: function () {
                player.currentTime(player.currentTime() - step);
            },
        });

        if (!props.isMobile) {
            const backwardButton = player.controlBar.addChild(new backwardButtonClass(), {}, 0);
            const forwardButton = player.controlBar.addChild(new forwardButtonClass(), {}, 2);
            forwardButton.addClass('vjs-seek-forward-button');
            backwardButton.addClass('vjs-seek-backward-button');
        }
    };

    const initAnnotationEngineEvents = (plugin) => {
        // on creating new annotations
        plugin.registerListener('onStateChanged', (event) => {
            const incomingAnnotations = event.detail;
            const existingAnnotations = refAnnotations.current;
            const newAnnotations = incomingAnnotations.filter(a => !existingAnnotations.map(a => (a.commentUid)).includes(a.id));
            if (!newAnnotations || newAnnotations.length === 0) { return; }

            if (newAnnotations.length === 1) {
                if (props.onAnnotationAdded) { props.onAnnotationAdded(newAnnotations[0]); }
            }
            if (newAnnotations.length > 1) {
                console.error('More than one new annotation found at a time!', newAnnotations);
            }
        });

        plugin.registerListener('addingAnnotationDataChanged', (event) => {
            if (event.detail.range) {
                props.onSelectionRangeChanged(event.detail.range);
            }
            if (event.detail.shape) {
                props.onSelectionShapeChanged(event.detail.shape);
            }
        });

        plugin.registerListener('enteredAddingAnnotation', (event) => {
            props.onSelectionRangeChanged(event.detail.range);
        });
    };

    const updateAnnotations = () => {
        const currentTime = refPlayer.current.currentTime();

        const annotationEngine = refAnnotationEngine.current;
        const loadedAnnotations = parseAnnotations(props.annotations);
        const existingAnnotations = annotationEngine.annotationState.annotations;
        const editedAnnotations = findEditedAnnotations(existingAnnotations, loadedAnnotations);

        const newAnnotations = loadedAnnotations
            .filter(a => !existingAnnotations.map(a => (a.id)).includes(a.id))
            .concat(...editedAnnotations);

        const deletedAnnotations = existingAnnotations
            .filter(a => !loadedAnnotations.map(a => (a.id)).includes(a.id))
            .concat(...editedAnnotations);

        deletedAnnotations.forEach(annotation => {
            annotationEngine.fire('destroyAnnotation', {
                id: annotation.id,
            });
        });

        newAnnotations.forEach(annotation => {
            annotationEngine.fire('newAnnotation', {
                id: annotation.id,
                range: annotation.range,
                shape: annotation.shape,
                commentStr: annotation.comments[0].body,
            });
        });

        refPlayer.current.currentTime(currentTime);
        // annotationEngine.fire('closeActiveAnnotation');
    };

    const parseAnnotations = (annotations) => {
        return annotations
            .filter(a => a.parentCommentUid === null)
            .map(annotation => {
                const json = JSON.parse(annotation.json);
                return ({
                    id: annotation.commentUid,
                    range: json.range,
                    shape: json.shape,
                    comments: [{
                        id: 1,
                        body: annotation.body,
                    }],
                });
            });
    };

    const findEditedAnnotations = (existingAnnotations, loadedAnnotations) => {
        return existingAnnotations
            .filter(a => loadedAnnotations.map(a => (a.id)).includes(a.id))
            .map(a => {
                const newText = loadedAnnotations.filter(l => l.id === a.id)[0].comments[0].body;
                return {
                    a: {
                        id: a.id,
                        range: a.range,
                        shape: a.shape,
                        comments: [{
                            id: 1,
                            body: newText,
                        }],
                    },
                    prevText: a.commentList.comments[0].body,
                    newText: newText,
                };
            })
            .filter(a => a.prevText !== a.newText)
            .map(a => a.a);
    };

    const handleTimeChanged = (currentTime) => {
        const topAnnotations = refAnnotations.current.filter(a => a.parentCommentUid === null);
        const annotationsWithRanges = topAnnotations.map(a => {
            const range = JSON.parse(a.json).range;
            return {
                uid: a.commentUid,
                range: {
                    start: range.start - 0.01,
                    end: (range.end || range.stop || 0) + 0.5,
                },
            };
        });
        let gap = currentTime - refLastTime.current;
        if (gap < 0 || gap > 0.3) {
            gap = 0;
        }
        const t1 = currentTime - gap;
        const t2 = currentTime;
        const matched = annotationsWithRanges.filter(a =>
            (gap <= a.range.end - a.range.start)
                ? (t1 >= a.range.start && t1 <= a.range.end) || (t2 >= a.range.start && t2 <= a.range.end)
                : a.range.start >= t1 && a.range.start <= t2,
        );
        const others = annotationsWithRanges.filter(a => matched.filter(m => m.uid === a.uid).length === 0);
        if (matched.length) {
            if (!refPlayer.current.paused() && refAnnotationEngine.current) {
                matched.forEach(a => {
                    refAnnotationEngine.current.fire('openAnnotation', { id: a.uid, pause: false, hideOthers: false });
                });
            }
        }
        others.forEach(a => {
            refAnnotationEngine.current.fire('closeAnnotation', { id: a.uid });
        });
        refLastTime.current = currentTime;
    };

    const setControlsVisibility = (player, hiddenControls) => {
        if (!player.el_) {
            return;
        }
        Object.keys(playerControls).map(x => { return player.controlBar[playerControls[x]].show(); });
        hiddenControls.map(x => { return player.controlBar[playerControls[x]].hide(); });
    };

    const playerControls = {
        play: 'playToggle',
        volume: 'volumePanel',
        seekbar: 'progressControl',
        timer: 'remainingTimeDisplay',
        playbackrates: 'playbackRateMenuButton',
        fullscreen: 'fullscreenToggle',
    };

    const videoJsOptions = {
        sources: [props.src],
        poster: props.poster,
        controls: props.controls,
        autoplay: props.autoplay,
        preload: props.preload,
        width: props.width,
        height: props.height,
        bigPlayButton: props.bigPlayButton,
        playbackRates: (!props.hidePlaybackRates && !props.hideControls.includes('playbackrates')) ? props.playbackRates : null,
        onPlayerUpdated: onPlayerUpdated,
    };

    return (
        <div id="playerWrapper" className={classes.videoPlayer}>
            <VideoPlayer {...videoJsOptions} />
        </div>
    );
},

// compare props to make memoization work
(prevProps, nextProps) => {
    return (prevProps.src === nextProps.src && prevProps.fps === nextProps.fps &&
            prevProps.annotations === nextProps.annotations);
});

VideoAnnotationEngine.defaultProps = {
    src: '',
    poster: '',
    controls: true,
    autoplay: false,
    preload: 'auto',
    playbackRates: [0.5, 1, 1.5, 2],
    hidePlaybackRates: false,
    className: '',
    hideControls: [],
    bigPlayButton: true,
    bigPlayButtonCentered: true,
    onReady: () => { },
    onPlay: () => { },
    onPause: () => { },
    onTimeUpdate: () => { },
    onSeeking: () => { },
    onSeeked: () => { },
    onEnd: () => { },
};

export default VideoAnnotationEngine;
